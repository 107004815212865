import { ZERO_WEI } from '@kwenta/sdk/constants'
import { MarketKeyByAsset, formatDollars, getDisplayAsset } from '@kwenta/sdk/utils'
import { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'

import { selectPerpsProvider } from 'state/futures/common/selectors'
import { MarkPriceInfos } from 'state/futures/common/types'
import { selectOffchainPricesInfo, selectPreviousDayPrices } from 'state/prices/selectors'
import { RootState } from 'state/store'
import { providerIsCrossMargin, unserializeV2Markets, unserializeV3Markets } from 'utils/futures'

export const selectMarkets = createSelector(
	selectPerpsProvider,
	(state: RootState) => state.home.optimismMarkets,
	(state: RootState) => state.home.baseMarkets,
	(futuresType, opMarkets, baseMarkets) => {
		return providerIsCrossMargin(futuresType)
			? unserializeV3Markets(baseMarkets)
			: unserializeV2Markets(opMarkets)
	}
)

export const selectMarketsWithPriceInfo = createSelector(
	selectMarkets,
	selectOffchainPricesInfo,
	selectPreviousDayPrices,
	(markets, prices, pastPrices) => {
		const markPrices: MarkPriceInfos = markets.reduce<MarkPriceInfos>((acc, market) => {
			const price = prices[market.asset]?.price ?? wei(0)
			return {
				...acc,
				[market.marketKey]: {
					price: wei(price).mul(wei(market.marketSkew).div(market.settings.skewScale).add(1)),
					change: prices[market.asset]?.change ?? null,
				},
			}
		}, {})

		return markets.map((m) => {
			const pastPrice = pastPrices.find(
				(price) => price.synth === getDisplayAsset(m.asset)?.toUpperCase()
			)
			const basePriceRate = markPrices[MarketKeyByAsset[m.asset]]

			const change =
				basePriceRate && pastPrice?.rate && basePriceRate.price.gt(0)
					? wei(basePriceRate.price).sub(pastPrice?.rate).div(basePriceRate.price)
					: ZERO_WEI

			return {
				asset: m.asset,
				marketKey: m.marketKey,
				price: formatDollars(basePriceRate?.price ?? '0', {
					suggestDecimalsForAsset: m.asset,
				}),
				change: change.toNumber(),
				priceDirection: basePriceRate?.change ?? null,
				isMarketClosed: m.isSuspended,
				closureReason: m.marketClosureReason,
			}
		})
	}
)

export const selectMirrorPosts = (state: RootState) => state.home.mirrorPosts
