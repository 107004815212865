import { SynthSuspensionReason } from '@kwenta/sdk/types'
import React, { FC, memo, ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import useIsMarketTransitioning from 'hooks/useIsMarketTransitioning'
import { selectPerennialMarkets } from 'state/futures/perennial/selectors'
import { useAppSelector } from 'state/hooks'
import { safeFromUnixtime } from 'utils/dates'
import { marketIsOpen, marketNextOpen, marketNextTransition } from 'utils/marketHours'

import Badge from './Badge'

type MarketBadgeProps = {
	currencyKey: string
	isFuturesMarketClosed?: boolean
	futuresClosureReason?: SynthSuspensionReason
	fallbackComponent?: ReactElement
	isFuturesMarketNewlyListed?: boolean
}

type TransitionBadgeProps = {
	isOpen: boolean
}

const TransitionBadge: FC<TransitionBadgeProps> = memo(({ isOpen }) => {
	const { t } = useTranslation()

	return (
		<StyledBadge color={isOpen ? 'yellow' : 'red'}>
			{t(`futures.market.state.${isOpen ? 'closes' : 'opens'}-soon`)}
		</StyledBadge>
	)
})

export const MarketBadge: FC<MarketBadgeProps> = memo(
	({
		currencyKey,
		isFuturesMarketClosed,
		futuresClosureReason,
		fallbackComponent,
		isFuturesMarketNewlyListed,
	}) => {
		const { t } = useTranslation()
		const markets = useAppSelector(selectPerennialMarkets)
		const market = markets.find((market) => market.marketKey === currencyKey)

		const { isOpen, nextClose, nextOpen } = useMemo(() => {
			if (!!market?.isCloseable) {
				return {
					isOpen: !market.isSuspended,
					nextOpen: safeFromUnixtime(market.marketHours.nextOpen),
					nextClose: safeFromUnixtime(market.marketHours.nextClose),
				}
			}
			return {
				isOpen: marketIsOpen(currencyKey),
				nextOpen: marketNextOpen(currencyKey),
				nextClose: marketNextTransition(currencyKey),
			}
		}, [currencyKey, market])

		const timerSetting = isOpen ? nextClose : nextOpen
		const isMarketTransitioning = useIsMarketTransitioning(timerSetting)

		if (typeof isFuturesMarketClosed !== 'boolean') return null

		if (isFuturesMarketClosed) {
			const reason = futuresClosureReason || 'unknown'
			return <Badge color="red">{t(`futures.market.state.${reason}`)}</Badge>
		}

		if (isMarketTransitioning && isOpen !== null) {
			return <TransitionBadge isOpen={isOpen} />
		}

		if (isFuturesMarketNewlyListed) {
			return <Badge color="green">{t('futures.market.state.new')}</Badge>
		}

		return fallbackComponent || null
	}
)

export default MarketBadge

const StyledBadge = styled(Badge)`
	padding: 1px 5px;
	line-height: 9px;
`
