import { FuturesMarketAsset, FuturesMarketKey, SynthSuspensionReason } from '@kwenta/sdk/types'
import { PerpsProvider } from '@kwenta/sdk/types'
import { floorNumber, getDisplayAsset } from '@kwenta/sdk/utils'
import Link from 'next/link'
import { memo, useMemo, useState } from 'react'
import Marquee from 'react-fast-marquee'
import styled from 'styled-components'

import ColoredPrice from 'components/ColoredPrice'
import CurrencyIcon from 'components/Currency/CurrencyIcon'
import MarketBadge from 'components/MarketBadge'
import { Body, NumericValue } from 'components/Text'
import { SNX_V3_ENABLED } from 'constants/futures'
import ROUTES from 'constants/routes'
import useWindowSize from 'hooks/useWindowSize'
import { useFetchLandingPageMarketData } from 'state/futures/hooks'
import { selectMarketsWithPriceInfo } from 'state/home/selectors'
import { useAppSelector } from 'state/hooks'
import media from 'styles/media'
import { themeColors } from 'styles/theme/colors'

type MarketOption = {
	asset: FuturesMarketAsset
	marketKey: FuturesMarketKey
	price: string
	change: number
	priceDirection: 'up' | 'down' | null
	isMarketClosed: boolean
	closureReason?: SynthSuspensionReason
	onCurrencyIconLoaded: () => void
}

const MIN_ICONS_MOBILE = 2
const MIN_ICONS_DESKTOP = 10

const MarketsMarquee = () => {
	const markets = useAppSelector(selectMarketsWithPriceInfo)
	const [iconsLoaded, setIconsLoaded] = useState(0)
	const { lessThanWidth, deviceType } = useWindowSize()
	const isMobile = useMemo(() => deviceType === 'mobile', [deviceType])

	useFetchLandingPageMarketData()

	const shouldRender = useMemo(() => {
		if (isMobile) {
			return MIN_ICONS_MOBILE <= iconsLoaded
		}
		return MIN_ICONS_DESKTOP <= iconsLoaded
	}, [iconsLoaded, isMobile])

	return (
		<FadeIn shouldRender={shouldRender}>
			<Marquee
				gradient
				pauseOnHover
				gradientColor={themeColors.dark.darkBackground}
				gradientWidth={lessThanWidth('lg') ? 25 : 200}
			>
				{markets?.map((m) => (
					<Market
						{...m}
						key={m.marketKey}
						onCurrencyIconLoaded={() => setIconsLoaded((prev) => prev + 1)}
					/>
				))}
			</Marquee>
		</FadeIn>
	)
}

const Market = memo(({ ...m }: MarketOption) => {
	return (
		<Link
			href={ROUTES.Markets.MarketPair(
				m.asset,
				SNX_V3_ENABLED ? PerpsProvider.SNX_V3_BASE : PerpsProvider.SNX_V2_OP
			)}
		>
			<MarketContainer>
				<StyledCurrencyIcon
					currencyKey={m.marketKey}
					width={23}
					height={23}
					onLoad={m.onCurrencyIconLoaded}
					priority
				/>
				<AssetName size="small">{getDisplayAsset(m.asset)}</AssetName>
				<ColoredPrice size="xsmall" priceChange={m.priceDirection}>
					{m.price}
				</ColoredPrice>
				<MarketBadge
					currencyKey={m.asset}
					isFuturesMarketClosed={m.isMarketClosed}
					futuresClosureReason={m.closureReason}
					fallbackComponent={
						<NumericValue
							size="xsmall"
							suffix="%"
							colored
							value={floorNumber(m.change ? m.change * 100 : '0', 2)}
						/>
					}
				/>
			</MarketContainer>
		</Link>
	)
})

const MarketContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3px;
	margin: 0 7px;
	padding: 7px 0;
	width: 100%;
	border: ${(props) => props.theme.colors.selectedTheme.landing.border};
	border-left: none;
	border-right: none;
	${media.lessThan('lg')`
		flex-direction: row;
		border: none;
  `}
`

const StyledCurrencyIcon = styled(CurrencyIcon)`
	border: ${(props) => `1px solid ${props.theme.colors.common.primaryWhite}`};
	border-radius: 50%;
`

const AssetName = styled(Body)`
	margin: 0 4px;
`

const FadeIn = styled.div<{ shouldRender: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	opacity: ${({ shouldRender }) => (shouldRender ? 1 : 0)};
	transition: opacity 0.3s ease-in-out;
`

export default MarketsMarquee
