import { NetworkId } from '@kwenta/sdk/types'

export const QUERY_KEYS = {
	Network: {
		EthGasPrice: ['network', 'ethGasPrice'],
		ENSNames: (addresses: string[]) => ['network', 'ensNames', addresses],
		ENSAvatar: (ensName?: string | null) => ['network', 'ensNames', ensName],
	},
	CoinGecko: {
		TokenPrices: (tokenAddresses: string[], platform: string) => [
			'cg',
			'prices',
			tokenAddresses.join('|'),
			platform,
		],
	},
	Futures: {
		Trades: (networkId: NetworkId, currencyKey: string | null) => [
			'futures',
			'trades',
			networkId,
			currencyKey,
		],
		TotalTrades: (networkId: NetworkId) => ['futures', 'totalTrades', networkId],
	},
	Files: {
		Get: (fileName: string) => ['files', 'get', fileName],
		GetMultiple: (fileNames: string[]) => ['files', 'getMultiple', fileNames],
	},
}

export default QUERY_KEYS
